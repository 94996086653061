.our--work {
    height: 100vh;


}

.work {
    &--categories {
        margin-bottom: 50px;
        position: relative;
        z-index: 2;
    }

    &--toolbar {
        ul {
            @extend %df;
            @extend %jcc;

            li {
                font-weight: $normal;
                font-size: $base_font;
                transition: all 0.3s ease-out;
                padding: 0 20px;
                cursor: pointer;

                a {
                    color: $black;
                }

                &.selected {
                    font-weight: $bold;
                }
            }
        }
    }

    &--list {
        @extend %df;
        flex-wrap: wrap;
        position: relative;
        margin-top: 30px;

        &:after {
            content: "";
            display: block;
            height: 100%;
            position: absolute;
            top: 0;
            right: 0;
            background-color: #f0f0f0;
            z-index: 2;
            width: 1px;
        }
    }

    &--item {
        width: calc(70% / 2);
        overflow: hidden;
        position: relative;
        padding-bottom: 13%;
        margin: 0 auto;
        @media only screen and (max-width: 500px) {
            width: 70%;
        }

        &.custom {
            .work--item {
                &-type {
                    img {
                        max-width: 65px;
                    }
                }
                &-header {
                    padding: 0 4%;
                }
            }
        }

        &:hover {
            .work--item-circle {
                img {
                    transform: translate(-50%, -50%) scale(1);
                }
            }
        }

        &-container {
            padding: 0 9%;
            position: relative;
        }

        &-inner {
            width: 100%;
            @extend %df;
            @extend %jcc;
            @extend %aic;
            position: relative;
        }
        &-grad {
            pointer-events: none;
            background: radial-gradient(center, hsla(0, 0%, 100%, .19), hsla(0, 0%, 100%, 0));
            z-index: 2;
            position: absolute;
            top: 50%;
            left: 50%;
            width: 100%;
            height: 100%;
            transform: translate(-50%, -50%);
            transform-origin: center;
            border-radius: 50%;
            transition: transform 0.45s ease;
        }

        &-circle {
            position: absolute;
            top: 0;
            width: 100%;
            padding-bottom: 100%;
            transform-origin: center;
            border-radius: 50%;
            transition: transform 0.45s ease;
            display: block;
            background-color: #EDF1F2;
        }

        &-header {
            width: 100%;
            padding: 30px 4% 0;
            text-align: center;
            flex-direction: column;
            z-index: 6;
            @extend %df;
            @extend %jcc;
            @extend %aic;
        }

        &-type {
            font: $light $xl5_font $fontFamily;
            display: block;
            color: $black;
            transition: transform 0.5s ease;
            flex: 0 0 auto;
            img {
              vertical-align: middle;
              max-width: 120px;
            }
        }

        &-image {
            max-width: 100%;
            width: 70%;
            z-index: 5;
            opacity: 1;
            margin: 0 auto;
            position: relative;
            display: block;

            img {
                vertical-align: middle;
            }

            &.work--item-show {
                transition: opacity 0.3s ease, transform 0.3s ease;
            }
        }

        &-outside {
            // transform: translateY(80px);
            text-align: center;
            position: relative;
            padding: 0 9%;

            &-brandname {
                font: $bold $xxl_font $fontFamily;
                color: #585556;
            }

            &-para {
                font: $bold $medium_font $fontFamily;
                color: #585556;
                position: relative;
                text-align: center;

                &.two {
                    display: none;
                }

                &::after {
                    content: 'View';
                    font-family: 'Montserrat';
                    text-decoration: underline;
                    display: block;
                    font-weight: $semiBold;
                    margin-top: 5px;
                    transition: opacity 0.3s ease;
                }
            }
        }

        &-loaded {
            .work--item-image {
                opacity: 1;
                transition: opacity 1s ease;
            }
        }

        &:hover .work--item-outside-para::after {
            opacity: 1;
        }
    }
}

.workItem--empty {
    .workItem__header {
        top: 50%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        max-height: 100%;
    }
}

.shiny-metals-wrap {
    padding-bottom: 70px;

    h3 {
        font-size: $xxl_font;
        margin-bottom: 40px;
        font-weight: $bold;
        text-align: center;
    }

    .total-metals {
        font-size: 15em;
        font-weight: $bold;
        color: $primary_green;
        margin: 0 auto;
        text-align: center;
        padding-left: 20px;
    }

    .metals-list {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    ul {
        font-size: $xxl_font;
        max-width: 768px;
        margin: 30px auto;
        display: inline-flex;
        flex-direction: column;
        align-items: flex-start;
        position: relative;
        li {
            width: auto;
            margin-bottom: 20px;
            position: relative;
            z-index: 1;
            font-weight: $bold;
        }
    }

    .and-growing {
        font-weight: $bold;
        font-size: $xxl_font;
        color: $primary_green;
        text-align: center;
    }
}
