
.seo{
    &--header{
        position: fixed;
        top: 0;
        padding-top: 15px;
        padding-bottom: 15px;
        flex-direction: row;
        justify-content: space-between;
        box-shadow: $boxShadow;
    }
    &--heading{
        font-size: 2.5em;
        line-height: 1.3;
        margin-bottom: 10px;
    }
    &--subheading{

    }
    &--container{
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: space-between;
        
        .banner{
            &--slider{
                .slick-dots{
                    bottom: 60px ;
                    text-align: left !important;
                    width: fit-content;

                    li{
                        button{
                            &::before{
                                color: $white;
                            }
                        }
                    }
                }
            }
        }

        &-title{
            padding-top: 50px;
            padding-bottom: 100px;
            background-image: url('../../images/seo/mobile-banner.webp');
            background-repeat: no-repeat;
    
        }
        &-form{
            width: 95%;
            margin-top: -55px;
            padding: 30px 40px;
            border-radius: 20px;
            box-shadow: $boxShadow;
            z-index: 1;
            &-wrap{
                h2{
                    font-size: $base_font;
                    line-height: 1.3;
                }
                &-feild{
                    margin-top: 20px;
                    display: flex;
                    flex-direction: column;

                    .form-group{
                        input{
                            padding: 10px;
                            border: 1px solid $fontGrey;
                            width: 100%;
                            border-radius: 5px;
                            &::placeholder{
                                color: $black;
                            }
                        }
                        &-checkbox{
                            position: relative;
                            label {
                                position: relative;
                                cursor: pointer;
                                display: flex;
                                align-items: center;
                                line-height: 1.2;
                               
                                &:before {
                                    content: '';
                                    background-color: $white;
                                    border: 1px solid rgba($black, 0.5);
                                    padding: 10px;
                                    display: inline-block;
                                    position: relative;
                                    vertical-align: middle;
                                    cursor: pointer;
                                    margin-right: 10px;
                                    background-color: $primary_green;
                                    // border-radius: 5px;
                                }
                    
                                // a {
                                //     display: inline-block;
                                //     white-space: pre-line;
                                // }
                            }
                            input {
                                display: none;
                                cursor: pointer;
                              
                                &:checked {
                                    + {
                                        label {
                                            &:after {
                                                content: '';
                                                display: block;
                                                position: absolute;
                                                top: 1px;
                                                left: 7px;
                                                width: 6px;
                                                height: 14px;
                                                border: solid $black;
                                                border-width: 0 2px 2px 0;
                                                transform: rotate(45deg);
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        select{
                           
                            padding: 12px 20px 12px 10px;
                            border: 1px solid $fontGrey;
                            border-radius: 5px;
                            margin-right: 10px;

                    }
                    }

                }
                .submit{
                    width: 100%;
                    justify-content: center;
                }
                .error{
                    margin-top: 5px;
                    display: block;
                    position: absolute;
                    // bottom: 0;
                    // left: 0;
                }
                .contrycode--wrap{
                    display: flex;
                    
                    &-option{
                        position: relative;
    
                        // &::after{
                        //     content: '\203A';
                        //     position: absolute;
                        //     right: 12px;
                        //     top: 6px;
                        //     font-size: 2.3em;
                        //     pointer-events: none;
                        //     transform: rotate(90deg);
                        // }
                        &::after{
                            
                            content: '';
                            background-image: url('../../images/seo/dropdown.svg');
                            position: absolute;
                            right: 10px;
                            top: 5px;
                            font-size: 2em;
                            pointer-events: none;
                            width: 30px;
                            height: 30px;
                        }
    
                        #countryCode{
                            cursor: pointer;
                        }
                    }
                }
                .contrycode--wrap{
                    display: flex;
                }
            }
        }
    }
}

.agency{
    &--container{
        padding-top: 50px;
        padding-bottom: 90px;

        .subhead{
            line-height: 1.5;
        }
        &-achivements{
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;
            margin: 50px auto 0;
            .achivements-card{
                background-color: $cardGrey;
                padding: 20px;
                border-radius: 15px;
                margin-bottom: 20px;
                
                width: calc(100%/2 - 10px);
                

                h3{
                    font-size: $xl5_font;
                }
                span{
                    display: block;
                    min-height: 30px;
                    line-height: 1.2;
                  
                }
            }
        }
        &-strategic{
            // display: flex;
            // align-items: center;
            // justify-content: space-between;
            // flex-direction: column;
            .strategic-card{
                display: flex;
                align-items: center;
                flex-direction: column;
                justify-content: space-between;
                padding: 20px;
                position: relative;
                margin-bottom: 30px;
                
               
                .detail-wrap{
                    width: 80%;
                    min-height: 160px;
                }
                span{
                    display: block;
                    line-height: 1.3;
                    
                }
                .client-comment{
                    text-decoration: underline;
                    cursor: pointer;
                }

                .client{
                    &-thought{
                        border-radius: 20px;
                        padding: 35px;
                        background-color: $primary_green;
                        position: absolute;
                        top: 0;
                        opacity: 0;
                        transition: 0.2s ease-in-out;
                        pointer-events: none;
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        justify-content: space-between;
                        &.active{
                            opacity: 1;
                            pointer-events: all;
                        }

                        p{
                            line-height: 1.5;
                        }
                        .close{
                            position: absolute;
                            right: 30px;
                            top: 30px;
                            cursor: pointer;
                        }
                        // &::before{
                        //     content: '';
                        //     background-image: url('../../images/seo/close.webp');
                        //     background-repeat: no-repeat;
                        //     position: absolute;
                        //     top: 35px;
                        //     right: 30px;
                        //     width: 20px;
                        //     height: 20px;
                        //     cursor: pointer;

                        // }
                        
                    }
                    &-detail{
                        display: flex;
                        align-items: flex-start;
                    }
                }

            }
        }
    }
}

.clients{
    &--container{
        background-color: rgba($lightGrey, 0.1);
        padding-top: 50px;
        padding-bottom: 80px;
        &-card{
            // display: flex;
            // align-items: center;
            // justify-content: space-between;
            // flex-wrap: wrap;
            &-list{
               
                display: flex !important;
                align-items: center;
                flex-direction: column;
                margin-bottom: 70px;
                margin-right: 15px;

                h4{
                    text-align: center;
                    line-height: 1.2;
                }
            }

        }
    } 
    &--img{
        background-color: $white;
        border-radius: 20px;
        padding: 15px 15px;
        min-height: 96px;
        display: flex;
        align-items: center;
    }  
    
}

.services{
    &--container{
        padding-top: 50px;
        padding-bottom: 20px;
        &-wrap{
          
                display: flex;
                align-items: flex-start;
                justify-content: space-between;
                flex-wrap: wrap;
                margin-top: 40px;

              

                &-details{
                    margin-top: 20px;
                    li{
                        width: 100%;
                         margin-bottom: 40px;
                        figure{
                            padding: 10px;
                            background-color: $primary_green;
                            border-radius: 50%;
                            width: fit-content;
                        }
                    }
                }
        }

    }
}

.piller{
    &--container{
        // padding-top: 50px;
        padding-bottom: 30px;
        &-cards{
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            flex-direction: column;
            &-detail{
                border: 1px solid $lightGrey;
                border-radius: 20px;
                padding: 30px;
                width: 100%;
                margin-bottom: 30px;
                
            }
        }
    }
}

.brands{
    &--container{
        // padding-top: 50px;
        padding-bottom: 30px;
        &-cards{
            // display: flex;
            // align-items: flex-start;
            // justify-content: space-between;
            // flex-direction: column;
            
            &-detail{
                border: 1px solid $lightGrey;
                border-radius: 20px;
                padding: 30px;
                width: 100%;
                margin-bottom: 30px;
                figure{
                    padding: 10px;
                    background-color: $primary_green;
                    border-radius: 50%;
                    width: fit-content;
                    img{
                        width: 38px;
                        height: 38px;
                    }
                }

                h4{
                    font-size: $large_font;
                }
            }
        }
    }
}

.engines{
    &--container{
        padding-bottom: 60px;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        
        &-comments{
            background-color: rgba($lightGrey, 0.1);
            padding: 20px;
            border-radius: 20px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
         
            &-detail{
                p{
                    line-height: 1.5;
                }
            }
            &-author{
                span{
                    display: block;
                    font-size: $base_font;
                    font-weight: $normal;
                    line-height: 1.5;
                }
            }
        }
        &-details{
            background-color: $primary_green;
            padding: 30px;
            border-radius: 20px;
            margin-bottom: 20px;
           
            .list-items{
                list-style: disc;
                margin-top: 20px;
                margin-left: 20px;
                li{
                    margin-bottom: 10px;
                    line-height: 1.3;
                }
            }
        }
    }
}

.contact{
    &--container{
        padding-bottom: 60px;
        position: relative;
        &-wrap{
            padding: 50px 40px;
            border-radius: 20px;
            background-color: $primary_green;
            
            // &::after{
            //     content: '';
            //     background-image: url('../../images/seo/wave3.webp');
            //     background-repeat: no-repeat;
            //     position: absolute;
            //     bottom: 0;
            //     right: 0;
            //     width: 100%;
            //     height: 100%;
            // }

            // &::after{
            //     content: '';
            //     background-image: url('../../images/seo/wave.webp');
            //     background-repeat: no-repeat;
            //     position: absolute;
            //     bottom: 60px;
            //     right: 15px;
            //     height: 200px;
            //     width: 200px;
            //     background-size: cover;
            // }
            // &::before{
            //     content: '';
            //     background-image: url('../../images/seo/wave.webp');
            //     background-repeat: no-repeat;
            //     position: absolute;
            //     bottom: -5px;
            //     left: 26px;
            //     height: 315px;
            //     width: 305px;
            //     background-size: cover;
            //     transform: rotate(90deg);
            // }

            h2{
                // font-size: 3em;
                font-size: $xl5_font;
                font-weight: $semiBold;
                font-family: $fontFamily;
                line-height: 1.3;
            }
            .contact-us{
                background-color: $white;
                padding: 15px 40px;
                color: $black;
                font-weight: $semiBold;
                font-size: $large_font;
                border-radius: 40px;
                margin: 30px auto 0;
                width: fit-content;
                display: flex;

            }
        }
    }
}

.seo-footer{
    background-color: rgba($lightGrey, 0.1);
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 30px;

    .terms-wrap{
        margin-top: 10px;
        a{
            display: inline-block;
            color: $black;
           
            
            
        }
    }
    p, a{
        font-size: $base_font;
        font-family: $fontFamily;
        font-weight: $normal;
    }

    
}

.thankyou{
    &-page{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 85vh;
        
        img{
            width: 60px;
        }
    }
}

.head{
    font-size: $xl5_font;
    text-align: center;
    font-weight: $semiBold;
    font-family: $fontFamily;
    line-height: 1.3;
}

.border{
    &--radius{
        border-radius: 50%;
    }
}