.services--slider {
	.service--slide {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		padding: 30px 0 30px 20px;
		backface-visibility: hidden;
		background: transparent;
		.slide-inner {
			border-radius: 21px;
			overflow: hidden;
			box-shadow: 0 30px 30px rgba(#000, 0.16);
		}
		.slide--content {
			padding: 20px;
			h4 {
				font-size: $medium_font;
				font-weight: $bold;
				margin-bottom: 10px;
			}
			p {
				font-size: $small_font;
				margin-bottom: 15px;
			}
			span {
				display: block;
				font-weight: $bold;
				margin-bottom: 10px;
				font-size: $small_font;
			}
			ul {
				padding-left: 20px;
				li {
					list-style-type: disc;
					font-size: $small_font;
					font-weight: $bold;
					line-height: 1.4;
				}
			}
		}
	}
}
