.havas{
    &--wrap{
        padding: 10px;
        .banner--image{
            width: 100%;
        }

        p, h3, a{

            margin-bottom: 20px;
            font-size: $medium_font;
            font-family: $fontFamily;
            line-height: 1.5;

        }

        h1{

            font-size: $xl5_font;
            font-weight: $bold;
            font-family: $fontFamily;
            color: #E10610;
            text-align: left;
            margin-bottom: 20px;
            line-height: 1.2;
        }

    }
    &--content{
        padding: 20px;

        &-group {
            a {
                text-decoration: underline;
                word-break: break-all;
            }
        }
    }

}