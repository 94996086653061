.contact {
   .banner--image{
       width: 50%;
   }
    &--form {
        padding-bottom: 100px;
        .thankyou--message{
            font: $bold $large_font $fontFamily;
        }
        .nl {
            &-form {
                width: 100%;
                // margin: 0 auto;
                font: $bold 1.6em $fontFamily;
                line-height: 2;
                color: $black;
                position: relative;
                // padding-left: 70px;
                ul {
                    list-style: none;
                    margin: 0;
                    padding: 0;
                }

                input:not([type="checkbox"]),
                select {
                    width: 100%;
                    border-bottom: 1px solid #707070;
                    margin-bottom: 10px;

                    &::-webkit-input-placeholder {
                        color: $black;
                        font-size: 1em;
                    }

                    &::placeholder {
                        color: rgba(#585556, 0.3);
                    }
                    &.errored {
                      border-color: red;
                      box-shadow: 0 1px 0 red;
                    }
                }

                .error {
                    color: $secondary_red;
                    font-size: 9px;
                    position: absolute;
                    width: 100%;
                    bottom: -7px;
                    left: 0;
                    display: block;
                }
                .form--field {
                  position: relative;
                }

                .check--radio {
                    position: relative;
                    &-wrap {
                        display: flex;
                        align-items: flex-start;
                        margin-top: 15px;

                        input {
                            cursor: pointer;
                            margin-top: 5px;
                        }

                        label {
                            cursor: pointer;
                            margin-left: 10px;
                            font-size: 12px;
                        }
                    }
                }
            }

            &-field {
                display: inline-block;
                position: relative;

                &-toggle {
                    line-height: inherit;
                    display: inline-block;
                    color: $greyTint;
                    cursor: pointer;
                    border-bottom: 1px solid $black;
                }

                ul {
                    position: absolute;
                    visibility: hidden;
                    background: $white;
                    left: 0;
                    top: 50%;
                    font-size: 60%;
                    opacity: 0;
                    transform: translateY(-40%) scale(0.9);
                    transition: visibility 0s 0.3s, opacity 0.3s, transform 0.3s;

                    li {
                        color: $black;
                        position: relative;
                    }
                }

                &-go {
                    position: absolute;
                    right: 0;
                    top: 0;
                    height: 100%;
                    cursor: pointer;
                    background: rgba(0, 0, 0, 0.1);
                    width: 1.8em;
                    text-align: center;
                    color: transparent;

                    &:before {
                        content: "\e903";
                        font-size: 75%;
                        color: $black;
                        width: 100%;
                        line-height: 2.5;
                        display: block;
                    }
                }

                &-open {
                    z-index: 10000;

                    ul {
                        visibility: visible;
                        opacity: 1;
                        transform: translateY(-50%) scale(1);
                        transition: visibility 0s 0s, opacity 0.3s, transform 0.3s;

                        &.dropdown {
                            position: fixed;
                            transform: translate(-50%, -50%) scale(1);
                            left: 50%;
                        }
                    }

                    &~.nl-overlay {
                        opacity: 1;
                        visibility: visible;
                        transition-delay: 0s;
                    }
                }
            }
            &-submit {
                position: relative;
                font-size: 100%;
                min-width: 150px;
                text-align: center;
                justify-content: center;

                &-wrap {
                    margin-top: 20px;
                }
            }

            &-dd {
                ul {
                    padding: 0.5em;
                }

                li {
                    padding: 0 1.5em 0 0.5em;
                    cursor: pointer;
                    white-space: nowrap;
                }

                &-checked {
                    color: #478982;

                    &:before {
                        content: "\e911";
                        position: absolute;
                        right: 1em;
                        font-size: 60%;
                        // line-height: 3;
                    }
                }
            }

            &-ti {
                &-text {
                    ul {
                        min-width: 15em;

                        li {
                            input {
                                width: 100%;
                                padding: 0.2em 2em 0.2em 0.5em;
                                border-bottom: none;
                                color: $black;
                            }
                        }
                    }
                }
            }

            &-overlay {
                position: fixed;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: $white;
                opacity: 0;
                z-index: 9999;
                visibility: hidden;
                -webkit-transition: visibility 0s 0.3s, opacity 0.3s;
                -moz-transition: visibility 0s 0.3s, opacity 0.3s;
                transition: visibility 0s 0.3s, opacity 0.3s;
            }
        }

        .no-touch {
            .nl {
                &-dd {
                    li {
                        &:hover {
                            background: rgba(0, 0, 0, 0.05);
                        }

                        &:active {
                            color: #478982;
                        }
                    }
                }

                &-form {
                    .nl-submit {
                        &:active,
                        &:hover {
                            background: #69B1A9;
                        }

                        &:hover {
                            &:before {
                                background: #58a199;
                            }
                        }
                    }
                }
            }
        }
    }
}
