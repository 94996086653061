.page--about_us {
    .pivot-circle {
        position: absolute;
    }
    .banner--text {
       
        // @media only screen and (max-width: 500px) {
        //     font-size: 5px;
           
        // }
    }
    .info--blocks-section {
        background-color: #D5D5D5;
        &.show {
            .info--block {
                opacity: 1;
                transform: translate3d(0, 0, 0);
                h3,
                p,
                a {
                    transform: translate3d(0, 0, 0);
                    opacity: 1;
                }
                svg {
                    opacity: 1;
                }
            }
        }
    }
    .info--blocks {
        @extend %df;
        @media only screen and (max-width: 768px) {
            flex-direction: column;
            padding: 40px 0;
        }
        .info--block {
            opacity: 0;
            transition: all 1s cubic-bezier(0.23, 1, 0.32, 1);
            padding: 4% 6%;
            width: calc(100% / 3);
            @media only screen and (max-width: 768px) {
                opacity: 1;
                width: 100%;
                padding-left: 90px;
                margin-bottom: 40px;
                &:last-child {
                    margin-bottom: 0;
                }
            }
            svg,
            h3,
            p,
            a {
                transform: translate3d(0, 80px, 0);
                transition: all .6s cubic-bezier(0.23, 1, 0.32, 1);
                opacity: 0;
                @media only screen and (max-width: 768px) {
                    opacity: 1;
                    transform: none;
                }
            }
            svg {
                margin-bottom: 50px;
                width: 60%;
                transform: none!important;
                @media only screen and (max-width: 768px) {
                    margin-bottom: 20px;
                    max-width: 210px;
                }
            }
            h3 {
                font-size: $xl5_font;
                margin-bottom: 40px;
                font-weight: $light;
                @media only screen and (max-width: 768px) {
                    margin-bottom: 20px;
                }
            }
            p {
                margin-bottom: 40px;
                font-size: $medium_font;
                line-height: 1.8;
                @media only screen and (max-width: 768px) {
                    font-size: $xl_font;
                }
            }
            a {
                color: $black;
                text-decoration: underline;
                font-size: $xl_font;
                display: block;
            }
        }
    }
}

.pivot {
    width: 100%;
    border-radius: 800px;
    background-color: $white;
    padding-bottom: 40%;
}

.bossman {
    &--quote {
        h2 {
            overflow: hidden;
            padding-bottom: 10px;
            display: flex;
            flex-wrap: wrap;
            width: 90%;
            margin: auto;
            @media only screen and (max-width: 768px) {
                line-height: 1.5;
                font-size: 2.1em;
            }
            span {
                opacity: 1;
                color: #FBFBFB;
                transform: translateY(150px) rotate(5deg);
                transition: all .6s cubic-bezier(0.23, 1, 0.32, 1);
                line-height: 1.3;
                padding-right: 20px;
            }
        }
        blockquote {
            opacity: 0;
            transform: translate(0, 50px);
            transition: all .6s cubic-bezier(0.23, 1, 0.32, 1);
            @media only screen and (max-width: 768px) {
                opacity: 1;
                transform: none;
                margin-top: 20px;
            }
            p {
                font-size: $xl5_font;
                font-weight: $light;
                @media only screen and (max-width: 768px) {
                    font-size: $xxl_font;
                    line-height: 1.5;
                }
            }
        }
        &.show {
            h2 {
                span {
                    opacity: 1;
                    transform: translateY(0) rotate(0deg);
                    color: $black;
                }
            }
            blockquote {
                opacity: 1;
                transform: translate(0, 0);
            }
        }
    }
}

@keyframes makeLine {
    from {
        stroke-dasharray: 1000;
    }
    50% {
        stroke-dasharray: 2000;
    }
    to {
        stroke-dasharray: 1000;
    }
}

@keyframes makeLine1 {
    from {
        stroke-dasharray: 4580;
    }
    50% {
        stroke-dasharray: 9160;
    }
    to {
        stroke-dasharray: 4580;
    }
}

.our--clients-container {
    @extend %df;
    @extend %jcc;
    margin-bottom: 50px;
    overflow: hidden;
    position: relative;
    .mask {
        $this: &;
        &--container {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            pointer-events: none;
        }
    }
    .mask {
        min-width: 25vw;
        min-height: 25vw;
        border-radius: 50%;
        position: absolute;
        display: block;
        background-attachment: fixed;
        background-repeat: no-repeat;
        background-size: cover;
        background-position-y: 0;
        @media only screen and (max-width: 768px) {
            display: none;
        }
    }
    .mask-left {
        top: 0;
        left: 0;
        transform: translateX(-80px);
        background-position-x: -60vw;
        background-image: url('../../images/about-left-mask.webp');
    }
    .mask-right {
        bottom: 0;
        right: 0;
        transform: translateX(120px);
        background-image: url('../../images/about-right-mask.webp');
        background-position-x: 10vw;
    }
    .clients-wrap {
        width: 50%;
        padding: 5%;
        @media only screen and (max-width: 768px) {
            width: 80%;
        }
        h3 {
            font-size: $xl5_font;
            font-weight: $light;
            text-align: center;
        }
        .client-logos {
            @extend %df;
            flex-wrap: wrap;
            @extend %aic;
            .logo-block {
                width: calc(100% / 5);
                @extend %df;
                @extend %aic;
                @extend %jcc;
                @media only screen and (max-width: 768px) {
                    width: calc(100% / 3);
                }
                @media only screen and (max-width: 500px) {
                    width: calc(100% / 2);
                }
                img {
                    opacity: 0;
                    transform: translate3d(0, 20px, 0);
                    transition: all .6s cubic-bezier(0.23, 1, 0.32, 1);
                    transition-delay: attr(tr-delay);
                    width: 100%;
                    @media only screen and (max-width: 768px) {
                        opacity: 1;
                        transform: none;
                    }
                }
            }
        }
        &.show {
            .logo-block {
                img {
                    opacity: 1;
                    transform: translate3d(0, 0, 0);
                }
            }
        }
    }
}

.meeting--room-wrap {
    .parallax {
        background-repeat: no-repeat;
        background-attachment: scroll;
        height: 280px;
        background-size:cover;
        background-position: center;
    }
    img {
        width: 100%;
        visibility: hidden;
        vertical-align: middle;
    }
    .circle-wrap {

        background-color: $primary_green;
        box-shadow: 40px 40px 80px rgba(0, 0, 0, 0.5);
        text-align: center;
        @extend %df;
        @extend %aic;
        @extend %fdc;
        @extend %jcc;
        padding: 20px;
        position: relative;
        transition: all .6s cubic-bezier(0.23, 1, 0.32, 1);

        max-width: 250px;
        max-height: 250px;
        width: 80vw;
        height: 80vw;
        opacity: 1;
        border-radius: 50%;
        margin: -100px auto 100px;
        font-size: $xxl_font;

        &.show {
            opacity: 1;
        }
        p {
            margin-bottom: 20px;
            font-size: 1em;
            line-height: 1.5;
        }
        a {
            font-size: $base_font;
            font-weight: $bold;
            color: $black;
            text-decoration: underline;
        }
    }
}

.marquee--section {
    padding: 50px 0;
    position: relative;
    overflow: hidden;
    position: relative!important;
    top: 0;
    left: 0;
    @extend %df;
    @extend %aic;
    -ms-overflow-style: none;
    /* IE 11 */
    scrollbar-width: none;
    /* Firefox 64 */
    &::-webkit-scrollbar {
        width: 0px;
    }
    &.scrollright {
        transform: scale(-1);
    }
    @media only screen and (max-width: 768px) {
        font-size: 0.3em;
    }
    .slidesholder {
        @extend %df;
        @extend %aic;
        @media only screen and (max-width: 768px) {
            width: 100%;
        }
    }
    .horizontal--scroller {
        white-space: nowrap;
        min-height: 100%;
        @extend %df;
        p {
            white-space: nowrap;
            font-size: 8.500em;
            font-weight: $bold;
            padding: 0 90px;
            display: flex;
            flex-direction: column;
            width: auto;
            color: $black;
            align-items: flex-start;
            backface-visibility: hidden;
            @media only screen and (max-width: 768px) {
                width: auto;
                text-align: center;
                margin-bottom: 30px;
                padding: 0 40px;
                white-space: normal;
                &:last-child {
                    margin-bottom: 0;
                }
            }
            span {
                font-size: 0.5em;
                font-weight: $light;
                display: inline-block;
                white-space: nowrap;
                @media only screen and (max-width: 768px) {
                    font-size: inherit;
                    br {
                        display: none;
                    }
                }
            }
        }
    }
}

$animDelay: 20;
$dots: 26;
$triangles: 30;
#Group_12982 {
    transition: all 1s ease;
    transform: translate(-10.79px, -35.5px)!important;
}

.info--block:hover {
    .bulbs {
        #Group_12982 {
            transform: translate(-10.79px, 4.5px)!important;
        }
    }
    .wings {
        #Group_13207>path,
        #Group_13207>circle {
            fill: #fec106;
        }
        #Group_13205 path {
            fill: yellow;
        }
    }
    .space {
        #Line_73,
        #Line_74,
        #Line_75 {
            animation: blaster 650ms linear infinite;
        }
    }
}

#Group_13207>path,
#Group_13207>circle,
#Group_13205>g>path {
    transition: all 800ms ease;
}

@for $i from 1 through $dots {
    @if $i>24 {
        #Group_13207>circle:nth-child(#{$i}) {
            transition-delay: #{$i * $animDelay}ms;
        }
    }
    @else {
        #Group_13207>path:nth-child(#{$i}) {
            transition-delay: #{$i * $animDelay}ms;
        }
    }
}

@for $i from 1 through $triangles {
    #Group_13205>g:nth-child(#{$i})>path {
        transition-delay: #{$i * $animDelay}ms;
    }
}

@keyframes blaster {
    0% {
        stroke-dasharray: 10;
        stroke-dashoffset: -2;
    }
    100% {
        stroke-dasharray: 10;
        stroke-dashoffset: -66;
    }
}
