header {
    transition: all 1s cubic-bezier(.25, .46, .45, .94);
    @extend %df;
    padding-top: 20px;
    padding-bottom: 20px;
    width: 100%;
    position: relative;
    z-index: 100;
    flex-direction: column;
    &.absolute {
      position: absolute;
    }
    padding-top: 20px;
    .logo--wrapper {
        text-align: center;
        .logo {
            transform: translateY(-80px);
            width: 200px;
        }
    }
}

.hamburger {
    &-wrapper {
        display: flex;
        align-items: flex-end;
        height: auto;
        width: 80px;
        position: absolute;
        bottom: 45px;
        margin-left: 40px;
        transform: translateX(100%);
        right: 0;
    }
    width: 67px;
    height: 77px;
    position: relative;
    cursor: pointer;
    background-color: $primary_green;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0 100px 100px 0;
    flex-direction: column;
    padding: 10px;
    span {
        display: block;
        height: 2px;
        background: $primary_brown!important;
        transform: rotate( 0deg);
        transition: .25s ease-in-out;
        opacity: 1;
        width: 0;
        border-radius: 25px;
        margin-bottom: 7px;
        align-self: baseline;
    }
    &.open {
        span {
            margin-bottom: -2px;
            &:nth-child(1) {
                transform: rotate(135deg);
            }
            &:nth-child(2) {
                opacity: 0;
            }
            &:nth-child(3) {
                transform: rotate(-135deg);
            }
            &:nth-child(4) {
                opacity: 0;
            }
        }
    }
    &::before,
    &::after {
        content: '';
        position: absolute;
        display: block;
        pointer-events: none;
        background-color: transparent;
        left: 0;
        width: 50px;
        height: 50px;
        border-radius: 50%;
    }
    &::before {
        top: 0;
        box-shadow: -25px 25px 0 $primary_green;
        transform: translate(0, -50px);
    }
    &::after {
        bottom: 0;
        box-shadow: -25px -25px 0 $primary_green;
        transform: translate(0, 50px);
    }
}

.main--menu-mobile {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.menu--desktop {
    position: fixed;
    top: 0;
    left: 0;
    width: 5px;
    height: 100%;
    z-index: 10;
    @extend %df;
    @extend %aic;
    @extend %jcc;
    background-color: $primary_green;
    &_inner {
        @extend %df;
        @extend %aic;
        justify-content: space-evenly;
        flex-direction: column;
        height: 100vh;
        width: 100%;
        overflow: hidden;
        opacity: 0;
        -ms-overflow-style: none;
        /* IE 11 */
        scrollbar-width: none;
        /* Firefox 64 */
        &::-webkit-scrollbar {
            width: 0px;
        }
        &>ul {
            z-index: 1;
            li {
                margin-bottom: 5%;
                overflow: hidden;
                a {
                    display: inline-block;
                }
                .menu {
                    &--title {
                        display: block;
                        color: $primary_brown;
                        font: $blackBold $xl5_font $fontFamily;
                        display: block;
                        padding-top: 30px;
                        position: relative;
                        transition: all 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
                    }
                }
            }
        }
        .logo{
            width: 200px;
        }
    }
    .info-container {
        @extend %df;
        @extend %fdc;
        padding-top: 30px;
        .info-block {
            margin-bottom: 10%;
        }
    }
}

.social {
    ul {
        @extend %df;
        @extend %aic;
        li {
            margin-right: 20px;
            a {
                @extend %df;
                @extend %aic;
                @extend %jcc;
                font-size: 30px;
                color: #080202;
                &:hover {
                    color: rgba(0, 0, 0, 0.8);
                }
            }
        }
    }
}
