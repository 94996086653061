[data-barba-namespace="home"] {
    height: 100vh;
}
.home {
    .scroll-container {
        height: 100%;
    }
    &--wrapper {
        width: 100%;
        height: 100vh;
        overflow: hidden;
        position: relative;
        z-index: 1;
        &:before {
            content: '';
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.7);
            position: absolute;
            z-index: -1;
        }
        #video {
            position: fixed;
            min-width: 100%;
            min-height: 100%;
            width: auto;
            height: auto;
        }
        .common--cell {
            width: 100%;
            height: 100%;
        }
        .layer {
            width: 100%;
            height: 100%;
            z-index: 2;
        }
        .pivot-circle {
            justify-content: center;
            position: absolute;
            top: 35%!important;
            left: 50%!important;
            transform: translate(-50%, -50%)!important;
            display: flex!important;
            z-index: 88;
            width: 0;
            height: 0;
            &.oh {
                overflow: hidden;
            }
        }
        h1 {
            font: $blackBold 2.6em $fontFamily;
            color: $black;
            span {
                display: flex;
                justify-content: center;
            }
            a {
                color: $black;
                position: relative;
                span {
                    z-index: 2;
                    position: relative;
                }
                &::after,
                &::before {
                    content: attr(hov-text);
                    position: absolute;
                    left: 0;
                    top: 50%;
                    white-space: nowrap;
                    padding: 10px 30px;
                    border-radius: 100px;
                    font: $normal 20px $fontFamily;
                    pointer-events: none;
                    transform: translate3d(0, -50%, 0);
                    box-shadow: 0 0 0 4px $primary_green;
                    @media only screen and (max-width: 768px) {
                        content: '';
                        display: none;
                    }
                }
                &::before {
                    color: transparent;
                    transition: all .4s ease;
                    background-color: $primary_green;
                    z-index: 1;
                    left: 20px;
                }
                &::after {
                    color: $black;
                    opacity: 0;
                    transition: opacity 0.4s ease;
                    transition-delay: 400ms;
                    left: calc(100% + 30px);
                    background-color: #fff;
                    z-index: 3;
                }
                &:hover {
                    &::after,
                    &::before {
                        opacity: 1;
                        // transition-delay: 0ms;
                        left: calc(100% + 60px);
                    }
                }
            }
            .link-effect {
                text-decoration: none;
                background-image: linear-gradient($black, $black);
                background-position: 0% 1.1em;
                background-repeat: no-repeat;
                background-size: 100% 2px;
                transition: all 0.5s cubic-bezier(0.85, 0, 0.3, 1);
                margin-left: 15px;
                @media not all and (hover: none), (-ms-high-contrast: none) {
                    &:hover {
                        background-size: 0% 2px;
                        background-position: 100% 1.1em;
                        -webkit-text-fill-color: transparent;
                        -webkit-text-stroke-width: 1px;
                        -webkit-text-stroke-color: $black;
                    }
                    &:focus-within {
                        background-size: 0% 2px;
                        background-position: 100% 1.1em;
                        -webkit-text-stroke-color: $black;
                        -webkit-text-fill-color: transparent;
                        -webkit-text-stroke-width: 1px;
                    }
                }
            }
        }
    }
    &--circle {
        background: url(images/home-bg.webp) no-repeat;
        min-width: 100%;
        min-height: 100vh;
        width: auto;
        height: auto;
        background-attachment: fixed;
        background-position: center;
        background-size: cover;
        transition: filter 1000ms ease-in-out;
        transform: scale(2);
        will-change: transform;
        animation: 2s ease-in-out forwards scale;
        position: absolute;
        &.blur {
            filter: blur(10px);
        }
    }
    &--cover {
        width: 100%;
        height: 100%;
        background: black;
        position: absolute;
        top: 0;
        left: 0;
    }

    &--ball {
        @extend %df;
        @extend %aic;
        @extend %jcc;
        @extend %fdc;
        position: relative;
        height: 100vh;

        h1 {
            color: $white;
        }

        h1, h2 {
            font: $blackBold 3em $fontFamily;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            line-height: 1;
        }

        .invisible {
            visibility: hidden;
        }

        button {
            
            animation: scaleSmall 2s infinite linear;
            
            img {
                pointer-events: none;
            }
        }

        &-text {
            font: $bold $large_font $fontFamily;
        }
    }
    &--banner{
        background: url(images/home--baner.webp) no-repeat;
        background-size: cover;
        width: 100%;
        height: 100%;
        background-position: center;
        position: relative;
        z-index: 1;
        &:after{
            content: '';
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.7);
            position: absolute;
            top:0;
            left: 0;
            z-index: -1;
        }
    }
}

@keyframes scale {
    from {
        -webkit-transform: scale(2);
        transform: scale(2)
    }
    to {
        -webkit-transform: scale(1);
        transform: scale(1)
    }
}

@keyframes scaleSmall {
    0% {
        transform: scale(1);
    }
    50% {

        transform: scale(1.2);
    }
    100% {
        transform: scale(1);
    }
}

.ball {
    width: 0px;
    height: 0px;
    opacity: 0;
    overflow: hidden;
    transition: width 0.5s, height 0.5s;
    @extend %df;
    @extend %aic;
    @extend %jcc;
    @extend %fdc;
    position: absolute;

    p {
        color: $black;
    }

    &.active {
        width: 100vw;
        opacity: 1;
        height: 100vh;
        background: $primary_green;
        z-index: 3;
    }

    &-content {
        color: $black;
    }
}

.landscape_mobile {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: $white;
    z-index: 999;
    display: none;
  
    @media only screen and (max-width: 1023px) and (min-aspect-ratio: 13/9) and (min-width: 320px) and (orientation: landscape) {
      display: block;
    }
  
    .landscape_view {
      padding: 30px;
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
  
      .rotating_image {
        text-align: center;
        margin-bottom: 20px;
  
        img {
          width: 150px;
          margin: 0 auto;
        }
      }
  
      .landscape_text {
        text-align: center;
  
        h3 {
          font-size: $xxl_font;
          font-weight: $semiBold;
          position: relative;
          margin-bottom: 10px;
        }
  
        p {
          font-size: $medium_font;
          font-weight: $semiBold;
          line-height: 1.5;
          color: $fontGrey;
        }
      }
    }
  }
  
  @media only screen and (min-width: 580px) and (max-width: 600px) and (max-height: 740px) and (orientation: portrait) {
    .landscape_mobile {
      display: none !important;
    }
  }

  @-webkit-keyframes autofill {
    to {
      color: blue;
      background: transparent;
    }
  }

  .hide {
      display: none;
  }