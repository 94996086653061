.page--careers {
    .banner--image {
        margin-top: -50px;
    }
    .banner--text {
        margin-left: 0;
    }
    .banner--image-career {
        transform: translateY(30%) !important;
    }
    .link {
        opacity: 1;
    }
}

.culture {
    $this: &;
    &--section {
        padding-top: 50px;
        padding-bottom: 50px;
    }
    &--slider {
        margin: 50px auto 20px;
        position: relative;
        #{$this}--slide {
            @extend %df;
            @extend %fdc;
            @extend %aic;
            background: transparent;
            backface-visibility: hidden;
            @media only screen and (max-width: 500px) {
                padding: 0 30px;
            }
            .slide {
                &--img {
                    @extend %df;
                    @extend %jcc;
                    @extend %aic;
                    width: 91%;
                    @media only screen and (max-width: 500px) {
                        height: auto !important;
                        width: 70% !important;
                        background-color: $primary_green;
                        border-radius: 50%;
                    }
                }
                &--title {
                    font-size: $base_font;
                    margin-bottom: 25px;
                    margin-top: 15px;
                    text-align: center;
                    line-height: 1.3;
                }
                &--desc {
                    opacity: 0;
                    transition: opacity 0.5s ease-in-out;
                    line-height: normal;
                    text-align: center;
                    min-width: 200px;
                    font-size: $small_font;
                }
            }
            &.swiper-slide-active {
                .slide--img {
                    img {
                        filter: none;
                    }
                }
                .slide--title {
                    font-weight: $bold;
                }
                .slide--desc {
                    opacity: 1;
                }
            }
        }
        .circle-container {
            @extend %df;
            @extend %jcsb;
            @extend %aic;
            position: absolute;
            left: 0;
            top: 0;
            padding: 0 10px;
            width: 100%;
            .pivot-circle-inner {
                margin: 0 1.3%;
                &.grey {}
            }
        }
        .mask-container {
            z-index: 3;
            position: relative;
            position: absolute;
            top: 0;
            left: 0;
            pointer-events: none;
            box-shadow: 0 -10px 0 30px $white;
            &::after {
                content: '';
                width: calc(100% / 5);
                display: block;
                height: 100px;
                position: absolute;
                left: 0;
                top: 100%;
                transform: translateX(-50%);
                background: $white;
            }
            img {
                width: 100%;
                vertical-align: middle;
            }
        }
    }
    &--navigation {
        @extend %df;
        @extend %jcc;
        @extend %aic;
        #{$this}--prev {
            margin-right: 30px;
        }
        #{$this}--next,
        #{$this}--prev {
            @extend %df;
            @extend %jcc;
            @extend %aic;
            background-color: $primary_green;
            width: 50px;
            height: 50px;
            border-radius: 50%;
            transition: all 0.3s ease-in-out;
            cursor: pointer;
            font-size: 25px;
            &.swiper-button-disabled {
                cursor: not-allowed;
                filter: grayscale(10);
            }
        }
    }
}

.masonry--slider {
    width: 100%;
    overflow: hidden;
    .grid {
        height: 30vh;
        &-item {
            backface-visibility: hidden;
            width: auto;
            height: 100%;
            img {
                height: 100%;
                vertical-align: middle;
            }
        }
    }
}

.accordion--container {
    list-style: none;
    counter-reset: list-Counter;
    &>li {
        counter-increment: list-Counter;
        background-color: #F8F8F8;
        padding: 25px 0;
        margin-bottom: 10px;
        &:last-child {
            // margin-bottom: 0;
        }
        &>div:nth-child(1) {
            &::before {
                content: counter(list-Counter, decimal-leading-zero);
                position: absolute;
                top: 0;
                left: 0;
                font-weight: $bold;
                font-size: $large_font;
                font-family: $fontFamily;
            }
        }
    }
    .accordion-header {
        @extend %df;
        @extend %aic;
        @extend %jcsb;
        padding-left: 40px;
        position: relative;
        div {
            padding-left: 25px;
            border-left: 1px solid #707070;
        }
        a {
            min-width: 50px;
            height: 50px;
            background-color: $primary_green;
            border-radius: 50%;
            @extend %df;
            @extend %aic;
            @extend %jcc;
            font-size: 1.2em;
            color: $black;
        }
    }
    .accordion-description,
    .accordion-heading {
        font-size: $large_font;
        line-height: $large_font;
    }
    p {
        font-size: $large_font !important;
        text-align: left !important;
        span {
            font-size: inherit !important;
        }
    }
}

.career {
    &--title {
        margin-bottom: 50px;
        display: flex;
        align-items: center;
        // z-index: 99999;
        position: relative;
        h1 {
            font-weight: $bold;
            font-size: $large_font;
            color: $primary_brown;
            margin-left: 20px;
            line-height: 1.5;
        }
        &-link-arrow {
            color: $primary_brown;
            transform: rotate(180deg);
            transition: transform 0.2s ease-out;
            font-size: 30px;
        }
    }
}

.hidden-data {
    padding-top: 50px;
    padding-bottom: 50px;
    h4 {
        font-weight: $bold;
        font-size: $large_font;
    }
    h4,
    ul {
        margin-bottom: 15px;
    }
    p {
        color: $fontGrey;
        font-size: 1.15em;
        margin-bottom: 15px;
        line-height: 1.5;
        &+h4,
        &+ul {
            margin-top: 25px;
        }
    }
    ul {
        padding-left: 30px;
        &+h4,
        &+p {
            margin-top: 25px;
        }
        li {
            margin-bottom: 15px;
            line-height: 1.5;
            list-style-type: disc;
            color: $fontGrey;
            font-size: 1.15em;
        }
    }
    .btn {
        color: #000;
    }
}
